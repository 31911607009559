// .paragraphs-item-slider {
//   &.slider-type-1,
//   &.slider-type-2 {
//     .paragraphs-item-slider-image .field-name-field-textarea-body {
//       background-color: $secondary-color;
//     }
//   }
// }
@import "../../breakpoint/breakpoint";

body.sidebar {
  #main {
    .sidebar {
      border-color: $secondary-color;
      h2 {
        //border-bottom: 2px solid $secondary-color;
      }
      .field-name-field-paragraph-sidebar > .field-items > .field-item:after,
      .field-name-field-blockref-sidebar > .field-items > .field-item:after,
      .field-name-field-pg-blockref-blockref > .field-items > .field-item:after,
      .region-sidebar > .block:after {
        background-color: $secondary-color;
      }
      // .field-item {
      //   border-bottom: 2px solid $secondary-color;
      //   &:last-child {
      //     border-bottom: none;
      //   }
      // }
    }
  }
}

#main #content {
  div[id*=block-views-news-news-block],
  div[id*=block-views-events-events-block],
  div[id*=block-views-blog-blog-block],
  div[id*=block-ucsf-b1gfoot-xmlnews-startingpoint-xmlnews],
  div[id*=block-ucsf-b1gfoot-xmlevents-startingpoint-xmlevents] {
    & > h3 {
      border-bottom: 2px solid $secondary-color;
    }
  }
}

#main #content .field-name-field-pg-ref-pubs .field-label,
#main #content .field-name-field-pg-ref-profiles .field-label,
#main #content .field-name-field-person-publications-list .field-label,
#main #content .field-name-field-person-pub-override-list .field-label,
#main #content .block-ucsf-b1gfoot-publication > h3,
#main #content .block-ucsf-b1gfoot-profile > h3,
#main #content .block-ucsf-b1gfoot-profile > h3,
#block-views-blog-related-author-block > h3,
#block-views-blog-by-author-block > h3,
 {
  border-bottom: 2px solid $secondary-color;
}


.slider-no-image .region-content-top{
  #block-fieldblock-node-page-default-field-paragraph-sliders {
    &:after {
      background-color: $secondary-color;
    }
  }
}

.slider-image-behind .region-content-top{
  #block-fieldblock-node-page-default-field-paragraph-sliders {
    .paragraphs-items > .field > .field-items > .slick-list >
    .slick-track > .field-item {
      &:after {
        background-color: $secondary-color;
      }
      @include breakpoint($bp--960-down) {
        &:before {
          background-color: $secondary-color;
        }
      }
    }
    .group-slider-content {
      &:after {
        background-color: $secondary-color;
      }
    }
    .field-name-field-pg-slider-image {
      &:after {
        background-color: $secondary-color;
      }
    }
  }
}

.slider-image-left .region-content-top{
  #block-fieldblock-node-page-default-field-paragraph-sliders {
    &:after {
      background-color: $secondary-color;
    }
  }
}

.slider-image-right .region-content-top{
  .group-slider-content {
      background-color: $secondary-color;
  }
}


.slider-image-right .region-content-top{
  #block-fieldblock-node-page-default-field-paragraph-sliders {
    &:after {
      background-color: $secondary-color;
    }
  }
}


// .wysiwyg_quote {
//   &:before {
//   background-color: $secondary-color;
//   }
// }

#main #content #block-views-events-events-block {
  h2 {
    border-bottom: 2px solid $secondary-color;
  }
}

#main #content #block-views-publications-block {
  h2 {
    border-bottom: 2px solid $secondary-color;
  }
}

// .wysiwyg_quote:before {
//   background-color: $secondary-color;
// }

.view-display-id-blog_page .blog-author-name a,
.node-blog .byline-author a {
  color: $secondary-color;
}
