@import "../../utilities/_0_variables.scss";

$secondary-color: $color--blue;

@import "secondary-colors";


// body.sidebar {
//   #main {
//     .sidebar {
//       border-color: $secondary-color;
//     }
//   }
// }

// #block-views-news-news-block {
//   margin-bottom: 40px;
//   h2 {
//     border-bottom: 2px solid $secondary-color;
//   }
// }

// #block-ucsf-b1gfoot-xmlevents-startingpoint-xmlevents {
//   h2 {
//     border-bottom: 2px solid $secondary-color;
//   }
// }
